import { useLocation } from '@gatsbyjs/reach-router'
import { MDXProvider } from '@mdx-js/react'
import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import styled from 'styled-components'
import theme from '../theme'
import Blockquote from './Blockquote'
import { BlogCta } from './BlogCta'
import { Video } from './Video'
import YoutubeVideo from './YoutubeVideo'
import Box from './primitives/Box'

type QueryImage = IGatsbyImageData & {
  original: {
    width: number
    src: string
  }
}

interface Entry {
  body: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  frontmatter: {
    title: string
    date: string
    slug: string
    meta: {
      imageAlt?: string
      image: { w640: QueryImage; w1000: QueryImage }
    }
  }
}

const ChangelogEntry = ({
  entry: { body, frontmatter },
  standalone,
  display = 'block',
}: {
  entry: Entry
  standalone?: boolean
  display?: 'block' | 'none'
}) => {
  const { title, date, meta } = frontmatter
  const imageVariant =
    frontmatter.meta.image?.w640.original.width >= 2000 ? 'w1000' : 'w640'
  const cover = getImage(frontmatter.meta.image?.[imageVariant])
  const url = 'https://www.swarmia.com' + frontmatter.slug
  const pathname = useLocation().pathname

  function showChangelogLabel() {
    if (pathname != '/changelog/') {
      return (
        <>
          <Link to="/changelog/">Changelog</Link> ·{' '}
        </>
      )
    }
    return null
  }

  const heading = standalone ? <h1>{title}</h1> : <h2>{title}</h2>
  return (
    <ChangelogStyles className="changelog-entry" style={{ display }}>
      <h4>
        {showChangelogLabel()}
        {date}
      </h4>
      {!standalone ? <a href={frontmatter.slug}>{heading}</a> : heading}
      <Box
        className="changelog-entry"
        display="flex"
        justifyContent="center"
        css={
          imageVariant === 'w1000'
            ? `
            @media (min-width: 1000px) {
              margin: 0 -180px;
            }
        `
            : undefined
        }
      >
        {cover ? (
          <a
            href={meta.image.w640.original.src}
            target="_blank"
            rel="noreferrer"
            style={{ all: 'unset', cursor: 'pointer' }}
          >
            <GatsbyImage image={cover} alt={meta.imageAlt || ''} />
          </a>
        ) : null}
      </Box>
      <MDXProvider
        components={{
          // NOTE: If we start having many components here, implement lazy loading to keep things fast
          // See: https://www.gatsbyjs.com/docs/mdx/importing-and-using-components/#lazy-loading-components
          BlogCta,
          blockquote: props => <Blockquote {...props} url={url} />,
          Video,
          YoutubeVideo: props => <YoutubeVideo borderRadius={0} {...props} />,
        }}
      >
        <MDXRenderer>{body}</MDXRenderer>
      </MDXProvider>
    </ChangelogStyles>
  )
}

export default ChangelogEntry

const ChangelogStyles = styled.div`
  .gatsby-image-wrapper,
  .gatsby-resp-image-wrapper,
  .video-wrapper {
    margin: 2rem 0;

    &,
    img,
    video {
      border-radius: ${theme.radii['large']};

      @media screen and (min-width: ${theme.breakpoints['medium']}) {
        border-radius: ${theme.radii['extralarge']};
      }
    }
  }

  margin-bottom: 72px;

  p {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  h4 {
    opacity: 0.5;
    margin: 2rem 0;
  }

  .anchor {
    display: none;
  }
`
