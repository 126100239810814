import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'

const StyledVideo = styled.video`
  display: block;
  width: 100%;
`

function getFilePath(videoName: string, extension: string) {
  return `/video/${videoName}.${extension}`
}

export function Video(props: {
  /**
   * When adding videos, you need to include 3 different files:
   * - .jpg file (width 640px) for the "poster" image. This should have the same aspect ratio as the video
   * - .mp4 video file (for OS X and Windows)
   * - .webm video file (for Linux)
   *
   * For example, for the video named "dora-summary" we have:
   * - static/video/dora-summary.jpg
   * - static/video/dora-summary.mp4
   * - static/video/dora-summary.webm
   *
   * You can do the conversion e.g. by opening the original video file with "Kap" screen recorder
   */
  controls?: boolean
  name: string
  posterName?: string
}) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  })

  return (
    <div ref={ref} className="video-wrapper">
      <StyledVideo
        controls={props.controls ?? true}
        autoPlay
        loop
        muted
        playsInline
        poster={getFilePath(props.posterName || props.name, 'jpg')}
      >
        {inView ? (
          <>
            <source src={getFilePath(props.name, 'mp4')} type="video/mp4" />
            <source src={getFilePath(props.name, 'webm')} type="video/webm" />
          </>
        ) : null}
      </StyledVideo>
    </div>
  )
}
